<template>
  <div class="favq">
    <heand :tagnum="tagnum"></heand>

    <div class="enterprise clearfix">
      <div class="left">
        <ziyuannavleft />
      </div>
      <div class="right">
        <div class="soso_box">
          <h1 class="zhaosh_tit">区域要素管理</h1>
          <ul class="ipt">
            <li>
              行业：
              <el-select
                class="iipp"
                v-model="valuetype"
                placeholder="请选择"
                @change="labelchange($event,industry,1)"
              >
                <el-option
                  v-for="(item,index) in industry"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </li>
            <li>
              级别：
              <el-select
                class="iipp"
                v-model="valueaptitude"
                placeholder="请选择"
                @change="labelchange($event,invest,2)"
              >
                <el-option
                  v-for="(item,index) in invest"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </li>
            <li>
              园区类型：
              <el-select
                class="iipp"
                v-model="valuecapital"
                placeholder="请选择"
                @change="labelchange($event,stage,3)"
              >
                <el-option
                  v-for="(item,index) in stage"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </li>
            <li>
              状态：
              <el-select
                class="iipp"
                v-model="valuestate"
                placeholder="请选择"
                @change="labelchange($event,unit,5)"
              >
                <el-option
                  v-for="item in unit"
                  :key="item.unit_id"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </li>
            <li>
              搜索：
              <el-input v-model="keyword" placeholder="任意关键词"></el-input>
            </li>
            <li>
              <span class="seach" @click="querycondition">搜索</span>
            </li>
          </ul>
        </div>

        <div class="project_main">
          <dl class="project_tit">
            <dd>
              <span class="xiaz_btn" @click="$router.push({name:'quyugl'})">
                <a>+新增</a>
              </span>
            </dd>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width:4%">序号</td>
                <td style="width: 13%;text-align:left;">园区名称</td>
                <td style="width: 15%;text-align:left;">园区地址</td>
                <td style="width:5%;">园区级别</td>
                <td style="width:5%;">园区对接人</td>
                <td style="width:5%;">对接人联系方式</td>
                <td style="width:5%;">上报人</td>
                <td style="width:10%;">上报机构</td>
                <td style="width:10%;">操作</td>
                <td style="width:5%;">最新状态</td>
              </tr>
              <tr v-for="(item,index) in tabledata" :key="index">
                <td>{{index+1}}</td>
                <td style="text-align:left;">{{item.name}}</td>
                <td style="text-align:left;">{{item.address}}</td>
                <td>{{item.level}}</td>
                <td>{{item.charge}}</td>
                <td>{{item.phone}}</td>
                <td>{{item.uperson}}</td>
                <td>{{item.unit}}</td>

                <td>
                  <span class="lvzi_btn" @click="$router.push({name:'yuanqugledit',query:{id:item.id}})">编辑</span>

                  <el-popover placement="bottom" width="200" trigger="click" :ref="'popsh'+item.id">
                    <p style="font-size:16px; font-weight:700; text-align:center;">是否通过此项目</p>
                    <div class="btn">
                      <el-button id="but2" @click="getauditItem(item.id, 2)">驳回</el-button>
                      <el-button id="but1" @click="getauditItem(item.id, 1)">通过</el-button>
                    </div>
                    <el-button slot="reference" class="chengzi_btn">审核</el-button>
                  </el-popover>
                  <span
                    class="redzi_btn"
                    @click="$router.push({name:'quluglook',query:{id:item.id}})"
                  >查看</span>
                  <el-popover placement="bottom" width="300" trigger="click" :ref="'popsb'+item.id">
                    <div>
                      <p>选择机构</p>
                      <div class="dispatch">
                        <ul>
                          <li
                            v-for="(items,indexs) in dispatchorg"
                            :key="indexs"
                            :class="government == items.government_id?'active':''"
                            @click="getsection(items.government_id,items.government_name,item.id)"
                          >{{items.government_name}}</li>
                        </ul>
                      </div>
                      <div class="btn">
                        <!-- <el-button id="but2">取消</el-button> -->
                        <el-button id="but1" @click="getSectionPeople()">确定</el-button>
                      </div>
                    </div>
                    <el-button slot="reference" class="bluezi_btn">上报</el-button>
                  </el-popover>
                </td>
                <td>{{item.status}}</td>
              </tr>
            </table>
          </div>
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="Number(total)"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFiled,
  listOfGarden,
  getlistGetUnit,
  getsendGarden,
  passGarden
} from "@/api/index";
export default {
  data() {
    return {
      valuetype: "",
      valuestate: "",
      valueaptitude: "",
      valuecapital: "",
      optionsway: {},
      valueway: "",
      tagnum: 7,
      industry: [], // 能源
      invest: [], // 投资额
      stage: [], // 进度
      unit: [], // 部门
      industryid: "", // 能源
      investid: "", // 投资额
      stageid: "", // 进度
      unitid: "", // 部门
      wayid: "",
      keyword: "",
      page: 1,
      perpage: 10,
      checked: "",
      currentPage1: 1,
      way: "",
      tabledata: [],
      page: 1,
      perpage: 10,
      total: "",
      checkedCities: [],
      dispatchorg: [],
      checkAll: false,
      governmentname: "",
      government: "",
      itemid: "",
      advice: ""
    };
  },
  created() {
    this.getFiled();
    this.querycondition();
    this.getlistGetUnit();
  },
  methods: {
    querycondition() {
      this.getlistOfGarden();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getlistOfGarden();
    },
    async getFiled() {
      const res = await getFiled();
      this.industry = res.indu;
      this.invest = res.level;
      this.stage = res.cate;
      this.unit = res.status;
    },
    // 获取列表
    async getlistOfGarden() {
      const res = await listOfGarden({
        key: this.keyword,
        indu: this.valuetype,
        level: this.valueaptitude,
        cate: this.valuecapital,
        status: this.valuestate,
        page: this.page,
        perpage: this.perpage
      });
      this.tabledata = res.data_lis;
      this.total = res.maxnum;
    },
    // 上报
    async getlistGetUnit() {
      const res = await getlistGetUnit();
      this.dispatchorg = res;
    },
    getsection(index, name, id) {
      this.government = index;
      this.governmentname = name;
      this.itemid = id;
    },
    async getSectionPeople() {
      if (this.government == "") {
        this.$message({
          message: "请选择机构",
          type: "error"
        });
      }
      const res = await getsendGarden({
        id: this.itemid,
        org: this.governmentname
      });
      let refName = "popsb" + this.itemid;
      this.$refs[refName][0].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getlistOfGarden();
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    // 审核
    getauditItem(id, index) {
      this.itemid = id;
      this.advice = index;
      this.getpassGarden()
    },
    async getpassGarden() {
      const res = await passGarden({
        id: this.itemid,
        advice: this.advice
      });
      let refName = "popsh" + this.itemid;
      this.$refs[refName][0].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getlistOfGarden();
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    labelchange(e){
      console.log(e)
    }
  }
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  background: rgb(243, 243, 243);
  min-height: 100%;
  padding-bottom: 30px;
  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  margin: 1% 0;
  padding: 0 30px;
}
.right {
  width: calc(100% - 256px - 15px);
  height: 89vh;
  float: right;
  margin-left: 10px;

  .soso_box {
    background: #fff;
    padding: 22px 10px 10px 22px;
    margin-bottom: 15px;
    overflow: hidden;
    zoom: 1;

    .zhaosh_tit {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
    }

    .ipt {
      overflow: hidden;
      zoom: 1;
      li {
        display: block;
        width: 16.5%;
        height: 31px;
        float: left;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #333333;
        margin: 1% 0;

        /deep/.el-select {
          width: 60%;
          height: 30px;
          input {
            width: 100%;
            height: 30px;
            background: #ffffff;
            border: 1px solid #dddddd;
          }
          /deep/.el-input__icon {
            line-height: 30px !important;
          }
        }
        /deep/.el-input {
          width: 100%;
          height: 30px;
          input {
            width: 100%;
            height: 30px;
            color: #333;
            background: #ffffff;
            border: 1px solid #dddddd;
            padding: 0 10px;
          }
          ::-webkit-input-placeholder {
            color: #333;
          }
          /deep/.el-icon-circle-close {
            background: url("../../assets/merchantsradar/cuo.png") 50% 50%
              no-repeat;
          }
          .el-icon-circle-close:before {
            content: "";
          }
        }
      }

      li:nth-child(2) {
        width: 18%;
        /deep/.el-select {
          width: 60%;
        }
      }
      li:nth-child(3) {
        width: 20%;
        /deep/.el-select {
          width: 60%;
        }
      }
      li:nth-child(4) {
        width: 15%;
        /deep/.el-select {
          width: 60%;
        }
      }
      li:nth-child(5) {
        width: 20%;
        /deep/.el-select {
          width: 60%;
        }
        /deep/.el-input {
          width: 75%;
        }
      }
      li:nth-child(6) {
        width: 10%;
      }
    }
  }

  .project_main {
    background: #fff;
    padding: 18px 10px 10px 22px;
    min-height: 74%;
    overflow: hidden;
    zoom: 1;

    .project_tit {
      overflow: hidden;
      zoom: 1;
      margin: 0px 0 6px 0;

      dt {
        float: left;
        font-weight: bold;
        font-size: 16px;
        color: #fe6a00;
        height: 30px;
        line-height: 30px;
      }

      dt:before {
        content: " ";
        padding: 0 3px 0 0;
        margin-right: 10px;
        background: #fe6a00;
      }

      dd {
        float: left;

        .xiaz_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          //   border: 1px solid #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          background: #fe6a00;
          margin: 1% 5px;
          padding: 0 20px;
          a {
            color: #fff;
          }
        }
      }
    }

    .project_tables {
      font-size: 14px;
      line-height: 44px;

      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
      span {
        margin: 0 2%;
      }
      .chengzi_btn {
        cursor: pointer;
        color: #fe6a00;
      }
      .lvzi_btn {
        cursor: pointer;
        color: #2cb54c;
      }
      .zizi_btn {
        cursor: pointer;
        color: #135de6;
      }
      .redzi_btn {
        cursor: pointer;
        color: #e51313;
      }
      .bluezi_btn {
        color: #0ebbf3;
      }
      .huangzi_btn {
        color: #e69554;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }

    //分页
    /deep/.el-pagination {
      float: right;
      margin-top: 20px;
    }
    /deep/.btn-prev {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.btn-next {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.el-pager li {
      border: 1px solid #888;
      margin: 0 3px;
      min-width: 27px;
    }
    /deep/.el-pager li.active,
    /deep/.el-pager li:hover {
      border: 1px solid #fe6a00;
      color: #fe6a00;
    }

    //分页end
  }
}
.btn {
  text-align: center;
  margin-top: 10px;
  #but1 {
    width: 72px;
    height: 30px;
    background: #fe6a00;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    padding: 0;
  }
  #but2 {
    width: 72px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #fe6a00;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #fe6a00;
    line-height: 30px;
    padding: 0;
  }
}
.dispatch {
  width: 100%;
  height: 138px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  z-index: 99;
  margin-top: 15px;
  ul {
    width: 100%;
    height: 138px;
    padding: 10px;
    overflow: auto;
    li {
      list-style: none;
      cursor: pointer;
      height: 30px;
      line-height: 20px;
      width: 100%;
      padding: 5px;
      &:hover {
        background-color: #eee;
        color: #e69554;
      }
      &.active {
        color: #e69554;
        background-color: #eee;
      }
    }
  }
}
.project_tables /deep/ .el-checkbox__label {
  display: none;
}
</style>